/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from "react";
import { axiosInstance } from "src/services/axios";
import { Toast } from "primereact/toast";
import { urlAcls } from "src/util/constants";
import { obsEnvName } from "src/services/observables";
import { replaceUrlPipeline } from "../../util/functions";
import { CrudPopOver } from "../crud-popover/crud-popover";

// Interfaces
import { GridRowCellAdd, DeletePayload, GridRow, GridRowCell, GridRowCellUpdate, TableData, UpdatePayload } from "./data-table-interfaces";

// Styles
import "./data-table.scss";
import { formatFlexTypeForDisplay, formatGridRowCellToTD } from "./data-table-functions";
import { UserGroup } from "../user-groups/user-group-interfaces";

export interface MainTableProps {
  tableData: TableData;
  // fetchAllACLs: (val: UserGroup[]) => void;
  removeColumnFromTable: (val: string) => void;
}

export function MainDataTable({ tableData, removeColumnFromTable }: MainTableProps) {
  const toast = useRef<Toast>(null);
  const [searchInput, setSearchInput] = useState("");
  const [currentEnvName, setCurrentEnvName] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalCellData, setModalCellData] = useState<GridRowCell>();

  tableData.userGroups = tableData.userGroups.filter((userGroup: UserGroup) => userGroup.checked);
  const [table, setTable] = useState<TableData>(tableData);

  // const closeModal = (needRefresh?: boolean) => {
  const closeModal = () => {
    setIsModalOpen(false);
    // if (needRefresh) {
    //   fetchAllACLs(table.userGroups);
    // }
  };

  useEffect(() => {
    obsEnvName.subscribe((env) => {
      setCurrentEnvName(env);
    });
  });

  const openModal = (col: GridRowCell, row: GridRow) => {
    setModalCellData({ ...col, flextypepath: row.flexObject });
    setIsModalOpen(true);
  };

  const handleRemoveIconClick = (flexObject: string) => {
    removeColumnFromTable(flexObject);

    // also remove from current table
    setTable((tableDataCurrent: TableData) => {
      tableDataCurrent.gridRows = tableDataCurrent.gridRows.filter((gridRow: GridRow) => gridRow.flexObject !== flexObject);
      return tableData;
    });
  };

  const rowMaker = (filterString?: string) => {
    setTable((tableDataCurrent: TableData) => {
      // now iterate data to build main row set for data table
      tableDataCurrent.gridRows = tableDataCurrent.gridRows.map((gridRowLoop: GridRow) => {
        // Now, all other keys on the row

        if (filterString) {
          const filterBy = filterString.toLowerCase();
          gridRowLoop.display = gridRowLoop.flexObject.toLowerCase().indexOf(filterBy) > -1;
        } else {
          gridRowLoop.display = true;
        }

        return gridRowLoop;
      });

      return tableDataCurrent;
    });
  };

  const searchHandle = (searchText: string) => {
    debugger;
    setSearchInput(searchText);
    rowMaker(searchText);
  };

  const updateCellLocally = (gridRowCellAdd, flextypepath, usergroupname, aclid?: string) => {
    setTable((tableDataCurrent: TableData) => {
      const matchingGridRows: GridRow[] = tableDataCurrent.gridRows.filter((gridRow: GridRow) => gridRow.flexObject === flextypepath);

      if (matchingGridRows.length > 1) {
        console.error("More than one ROW match on add", gridRowCellAdd);
      } else if (matchingGridRows.length === 0) {
        console.error("Zero ROW matches on add", gridRowCellAdd);
      } else {
        const matchingGridRow: GridRow = matchingGridRows[0];
        const matchingGridRowCells: GridRowCell[] = matchingGridRow.cells.filter((grc: GridRowCell) => grc.usergroupname === usergroupname);

        if (matchingGridRowCells.length > 1) {
          console.error("More than one CELL match on add", gridRowCellAdd);
        } else if (matchingGridRowCells.length === 1) {
          const matchingGridRowCells: GridRowCell[] = matchingGridRow.cells.filter((gridRowCell: GridRowCell) => gridRowCell.usergroupname === usergroupname);

          if (matchingGridRowCells.length !== 1) {
            console.error("More than one CELL match on update");
          } else {
            const indexToRemove = matchingGridRow.cells.indexOf(matchingGridRowCells[0]);
            matchingGridRow.cells[indexToRemove].aclid = aclid;
          }
        } else {
          const gridRowCell: GridRowCell = {
            ...gridRowCellAdd,
            placeholder: false,
            aclid: "",
            flextypeinternal: "",
          };

          matchingGridRow.cells.push(gridRowCell);
        }
      }

      return tableDataCurrent;
    });
  };

  const handleAdd = (gridRowCellAdd: GridRowCellAdd) => {
    const { flextypepath, usergroupname, allexcept, selected } = gridRowCellAdd;

    updateCellLocally(gridRowCellAdd, flextypepath, usergroupname);
    // update display

    // http call
    const URI = replaceUrlPipeline(urlAcls, currentEnvName);
    axiosInstance.post(URI, gridRowCellAdd).then(
      (requestCall) => {
        if (requestCall.status === 200) {
          const { flextypepath, usergroupname } = gridRowCellAdd;
          const aclid = requestCall.data.data.aclid;
          updateCellLocally(gridRowCellAdd, flextypepath, usergroupname, aclid);
          const msg = `${flextypepath} permissions record added for ${usergroupname}`;
          toast.current?.show({ severity: "success", summary: "Success", detail: msg, life: 3000 });
          // fetchAllACLs(userGroups);
        }
      },
      (err) => {
        toast.current?.show({ severity: "error", summary: "Error", detail: err?.response?.data?.message, life: 3000 });
      },
    );
  };

  const handleUpdate = (gridRowCellUpdate: GridRowCellUpdate) => {
    const { flextypepath, usergroupname, allexcept, selected, update } = gridRowCellUpdate;

    // update display
    setTable((tableDataCurrent: TableData) => {
      const matchingGridRows: GridRow[] = tableDataCurrent.gridRows.filter((gridRow: GridRow) => gridRow.flexObject === flextypepath);

      if (matchingGridRows.length > 1) {
        console.error("More than one ROW match on update", gridRowCellUpdate);
      } else if (matchingGridRows.length === 0) {
        console.error("Zero ROW matches on update", gridRowCellUpdate);
      } else {
        const matchingGridRow: GridRow = matchingGridRows[0];

        const matchingGridRowCells: GridRowCell[] = matchingGridRow.cells.filter((gridRowCell: GridRowCell) => gridRowCell.usergroupname === usergroupname);

        if (matchingGridRowCells.length > 1) {
          console.error("More than one CELL match on update", gridRowCellUpdate);
        } else if (matchingGridRowCells.length === 0) {
          console.error("Zero CELL matches on update", gridRowCellUpdate);
        } else {
          const matchingGridRowCell = matchingGridRowCells[0];

          // update permissions to new values
          matchingGridRowCell.allexcept = update.allexcept;
          matchingGridRowCell.selected = update.selected;
        }
      }

      return tableDataCurrent;
    });

    // http call
    const URI = replaceUrlPipeline(urlAcls, currentEnvName);
    const apiPayload: UpdatePayload = {
      aclid: gridRowCellUpdate.aclid,
      current: { allexcept, selected },
      update,
    };
    axiosInstance.put(URI, apiPayload).then(
      (requestCall) => {
        if (requestCall.status === 200) {
          const msg = `${flextypepath} permissions record added for ${usergroupname}`;
          toast.current?.show({ severity: "success", summary: "Success", detail: msg, life: 3000 });
        }
      },
      (err) => {
        toast.current?.show({ severity: "error", summary: "Error", detail: err?.response?.data?.message, life: 3000 });
      },
    );
  };

  const handleDelete = (gridRowCell: GridRowCell) => {
    const { flextypepath, usergroupname } = gridRowCell;
    // update display
    setTable((tableDataCurrent: TableData) => {
      const matchingGridRows: GridRow[] = tableDataCurrent.gridRows.filter((gridRow: GridRow) => gridRow.flexObject === flextypepath);

      const matchingGridRow: GridRow = matchingGridRows[0];

      const matchingGridRowCells: GridRowCell[] = matchingGridRow.cells.filter((gridRowCell: GridRowCell) => gridRowCell.usergroupname === usergroupname);

      if (matchingGridRowCells.length !== 1) {
        console.error("More than one CELL match on update", gridRowCell);
      } else {
        // delete the record
        const indexToRemove = matchingGridRow.cells.indexOf(matchingGridRowCells[0]);

        if (indexToRemove !== -1) {
          matchingGridRow.cells.splice(indexToRemove, 1);
        } else {
          console.error("Element not found in the array");
        }
      }

      return tableDataCurrent;
    });

    // http call
    const URI = replaceUrlPipeline(urlAcls, currentEnvName);

    const apiPayload: DeletePayload = {
      aclid: gridRowCell.aclid,
    };
    // { flextypepath, usergroupname }
    axiosInstance.delete(URI, { data: apiPayload }).then(
      (requestCall) => {
        if (requestCall.status === 200) {
          const { flextypepath, usergroupname } = gridRowCell;
          const msg = `${flextypepath} permissions record deleted for ${usergroupname}`;
          toast.current?.show({ severity: "success", summary: "Success", detail: msg, life: 3000 });
        }
      },
      (err) => {
        toast.current?.show({ severity: "error", summary: "Error", detail: err?.response?.data?.message, life: 3000 });
      },
    );
  };

  //
  //
  //  Main Table
  // if (!table || !Object.hasOwn(table, "userGroups") || !table.userGroups.length || !Object.hasOwn(table, "gridRows") || !table.gridRows.length) {
  if (!table || !Object.hasOwn(table, "userGroups") || !Object.hasOwn(table, "gridRows") || !table.gridRows.length) {
    return <h2>Data Loading...</h2>;
  }

  // Header row
  const userGroupColumns = tableData.userGroups
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((userGroup: UserGroup) => {
      return (
        <th key={userGroup.name} scope="col" className="column">
          <div className="d-flex justify-content-between bold">
            <div>
              <span>{userGroup.name}</span>
            </div>
            <div>
              <span className="btn-icon" onKeyUp={(e) => (e.key === "Space" ? () => handleRemoveIconClick(userGroup.name) : () => {})} onClick={() => handleRemoveIconClick(userGroup.name)}>
                <i className="bi bi-x" />
              </span>
            </div>
          </div>
        </th>
      );
    });

  // data row
  const arrFlexTypeRows: React.JSX.Element[] = [];

  tableData.gridRows
    .filter((gridRow: GridRow) => gridRow.display)
    .forEach((gridRow: GridRow) => {
      const rowElementsJsx: React.JSX.Element[] = [];

      // Flex Type column
      const keyFlexTypeCol = `flexType-${gridRow.flexObject}`;
      const flexTypeCol: React.JSX.Element = (
        <td key={keyFlexTypeCol} className="flextype-col istCol pb-2">
          {formatFlexTypeForDisplay(gridRow.flexObject as string)}
        </td>
      );
      rowElementsJsx.push(flexTypeCol);

      // Now Usergroup columns
      tableData.userGroups
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .forEach((userGroup: UserGroup) => {
          let jsx: React.JSX.Element;

          const matchingCells = gridRow.cells.filter((cell: GridRowCell) => cell.usergroupname === userGroup.name);

          if (matchingCells.length === 0) {
            jsx = formatGridRowCellToTD(
              gridRow,
              {
                placeholder: true,
                aclid: "",
                flextype: gridRow.flexObject,
                flextypeinternal: "",
                flextypepath: "",
                usergroupid: Number(userGroup.id),
                usergroupname: userGroup.name,
              },
              openModal,
            );
          } else if (matchingCells.length === 1) {
            jsx = formatGridRowCellToTD(gridRow, matchingCells[0], openModal);
          } else {
            console.error("More than one user group match found during render", userGroup.name);
          }

          rowElementsJsx.push(jsx);
        });

      arrFlexTypeRows.push(<tr key={`row-${gridRow.flexObject}`}>{rowElementsJsx}</tr>);
    });

  return (
    <div>
      <Toast ref={toast} />
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col" key="search" className="column corner-col">
              <div className="p-1 bold">
                <span>FlexType</span>
              </div>
              <div>
                <input type="text" value={searchInput} className="form-control" placeholder="Type to search..." onChange={(evt) => searchHandle(evt.target.value)} />
              </div>
            </th>
            {userGroupColumns}
          </tr>
        </thead>
        <tbody>{arrFlexTypeRows}</tbody>
      </table>
      <CrudPopOver isModalOpen={isModalOpen} modalCellData={modalCellData} closeModal={closeModal} addRightsHandle={handleAdd} updateRightsHandle={handleUpdate} deleteRightsHandle={handleDelete} />
    </div>
  );
}
