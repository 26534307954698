import React, { useState, useEffect } from "react";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import "./user-groups.scss";
import { UserGroup, UserGroupPropsInterface } from "./user-group-interfaces";

export function UserGroups({ userGroups, triStateVal, setTriStateVal, userGroupToggle }: UserGroupPropsInterface) {
  const [userGroupProps, setUserGroupProps] = useState([]);

  useEffect(() => {
    setUserGroupProps([...userGroups]);
    sortAndSet([...userGroups]);
    handleTriState();
  }, [userGroups]);

  const sortAndSet = (userGroupEdit: UserGroup[]) => {
    // sort first by name
    userGroupEdit = userGroupEdit.sort((a: UserGroup, b: UserGroup) => (a.name < b.name ? 1 : -1));
    // then by checked status
    userGroupEdit = userGroupEdit.sort((a: UserGroup, b: UserGroup) => (a.checked < b.checked ? 1 : -1));
    setUserGroupProps(userGroupEdit);
    handleTriState();
  };

  const toggleChecked = (userGroupToToggle: UserGroup, ind: number) => {
    const userGroupEdit: UserGroup[] = [...userGroupProps];
    userGroupToToggle.checked = !userGroupToToggle.checked;
    userGroupEdit[ind] = userGroupToToggle;
    userGroupToggle(userGroupToToggle, userGroupEdit);
    sortAndSet(userGroupEdit);
  };

  const handleTriState = () => {
    const checkUgs = userGroups.filter((ug: UserGroup) => ug.checked);
    if (!checkUgs.length) {
      setTriStateVal(null);
    } else if (checkUgs.length === userGroups.length) {
      setTriStateVal(true);
    } else {
      setTriStateVal(false);
    }
  };

  const triStateChange = (val: boolean | null) => {
    let value = val;
    if (value === false) {
      value = null;
    }
    setTriStateVal(value);
  };

  const searchHandle = (str: string) => {
    const filteredUGs = [...userGroups].filter((ug: UserGroup) => ug.name.toLowerCase().includes(str.toLowerCase()));
    sortAndSet(filteredUGs);
  };

  return (
    <>
      <div className="row right-side-row border-bottom-light">
        <div className="col-11">
          <input className="search-text" type="text" placeholder="Search User Groups..." onChange={(evt) => searchHandle(evt.target.value)} />
        </div>
        <div className="col-1 triStateCheckbox mt-2">
          <TriStateCheckbox className="p-checkbox-t" value={triStateVal} onChange={(e) => triStateChange(e.value)} />
        </div>
      </div>
      <div className="row fill-in-search">
        <div className="col-12">
          {userGroupProps.map((usergroup: UserGroup, index: number) => (
            <div className="accordionContent" role="presentation" key={`list-${usergroup.id}`} onClick={() => toggleChecked(usergroup, index)}>
              <span>{usergroup.name}</span>
              {/* <div className="d-flex">
                {usergroup?.flexType && (
                  <h6 className="m-0">
                    <span className="badge badge-secondary">{usergroup.flexType}</span>
                  </h6>
                )}
              </div> */}
              <input type="Checkbox" className="p-Checkbox ms-2" checked={usergroup.checked} onChange={() => {}} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
