import React, { useEffect, useState, JSX } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useHistory, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useOktaAuth } from "@okta/okta-react";
import "./appBar.scss";
import { useOkta, websocketUrl } from "src/constants";
import { AuthState, OktaAuth } from "@okta/okta-auth-js";
import { obsEnvName, obsOktaEmail, obsOktaToken, obsPipeLines } from "src/services/observables";
import { getUrl } from "src/util/axios-rest";
import { pipeLinesGet } from "src/util/constants";
import { switchEnvName } from "src/util/functions";
import { PopOver } from "../websocket-popover/websocket-popover";
import { Navbar } from "../Navbar";

/* DECORATED COMPONENT */
export function AppBar() {
  const [currentEnv, setCurrentEnv] = useState<string>("");
  const [selectedEnv, setSelectedEnv] = useState<string>("");
  const [nextEnv, setNextEnv] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>(null);
  const [confirmModalShow, setConfirmModalShow] = useState<boolean>(false);
  const [envArray, setEnvArray] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const updateUrlForEnv = (env: string = "sc") => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("env", env);
    const newSearchString = `?${searchParams.toString()}`;
    history.push({
      pathname: location.pathname,
      search: newSearchString,
    });
  };

  useEffect(() => {
    obsOktaEmail.subscribe((mail: string) => {
      setUserEmail(mail);
    });

    obsOktaToken.subscribe((token: string) => {
      if (token) {
        //  loadPipeLines
        getUrl(pipeLinesGet).then((res) => {
          if (res && res.data) {
            const { data } = res;
            obsPipeLines.next([...data]);
            setEnvArray([...data]);
          }
        });
      }
    });

    obsEnvName.subscribe((env) => {
      setCurrentEnv(env);
    });
  }, []);

  useEffect(() => {
    const env = queryParams.get("env");

    if (!env) {
      updateUrlForEnv();
    }
    switchEnvName(env);
  }, [history, queryParams]);

  const onViewClickHandler = (envName: string) => {
    setNextEnv(envName);
    setConfirmModalShow(true);
    setSelectedEnv(envName);
  };

  const hideConfirmModal = () => {
    setConfirmModalShow(false);
  };

  const acceptFunc = () => {
    setCurrentEnv(selectedEnv);
    switchEnvName(selectedEnv);
    history.push(`/?env=${selectedEnv}`);
    hideConfirmModal();
  };

  const rejectFunc = () => {
    hideConfirmModal();
    setSelectedEnv("");
  };

  //
  //
  // Websocket code
  let token: string;

  const [getModalOpen, setModalOpen] = useState(false);
  const [getModalHeader, setModalHeader] = useState("Calculating...");
  const [getModalMessage, setModalMessage] = useState<string | JSX.Element>("");
  const [getModalProgress, setModalProgress] = useState(0);

  const closeModal = () => {
    setModalOpen(false);
  };

  //
  //
  // OKTA
  let authState: Partial<AuthState> = {
    accessToken: {
      accessToken: "",
      claims: undefined,
      tokenType: "",
      userinfoUrl: "",
      expiresAt: 0,
      authorizeUrl: "",
      scopes: [],
    },
  };
  let oktaAuth: Partial<OktaAuth> = {
    signOut: () => {
      let resolveProm;
      const prom = new Promise<void>(resolveProm);
      // eslint-disable-next-line no-alert
      alert("Localhost Logout");
      resolveProm();
      return prom;
    },
  };

  if (useOkta) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const localOktaAuth = useOktaAuth();
    authState = localOktaAuth.authState;
    oktaAuth = localOktaAuth.oktaAuth;
  }

  const [socketUrl, setSocketUrl] = useState<string | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [wsMessage, setWsMessage] = useState<any>();

  useEffect(() => {
    if (socketUrl) {
      const wsClient = new WebSocket(socketUrl);
      // console.error("socketUrl:", socketUrl);

      wsClient.onopen = () => {
        wsClient.send(JSON.stringify({ action: "triggerExport", pipeline: "sc" }));
      };

      // Listen for messages
      wsClient.addEventListener("message", (event) => {
        setWsMessage(event.data);
      });

      return () => {
        wsClient.close();
      };
    }
    return () => {
      // empty close
    };
  }, [socketUrl]);

  // will return URL
  useEffect(() => {
    if (wsMessage) {
      const message = JSON.parse(wsMessage);
      const { url } = message.data;
      setModalHeader("PR Created, click below");
      setModalMessage(
        <span>
          See your Pull Request:{" "}
          <a href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
        </span>,
      );
      setModalProgress(100);
    }
  }, [wsMessage]);

  // Publish function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const publish = () => {
    if (useOkta) {
      const accToken = authState.accessToken.accessToken;

      // publish = () => {
      if (useOkta) {
        // on connection, send our authentication request
        // pass message
        const socketUrlWithToken = `${websocketUrl}?Authorization=Bearer ${accToken}`;
        setSocketUrl(socketUrlWithToken);
        // Open Modal
        setModalOpen(true);
      }
      // };
    } else {
      // Open Modal
      setModalOpen(true);

      // final, close out
      setTimeout(() => {
        setModalHeader("PR Created, click below");
        setModalMessage(
          <span>
            See your Pull Request:{" "}
            <a href="#" target="_blank" rel="noreferrer">
              TEST TEST TEST TEST TEST
            </a>
          </span>,
        );
        setModalProgress(100);
      }, 40 * 1000);
    }
  };

  // Logout
  const logout = async () => {
    oktaAuth.signOut();
  };

  const login = async () => oktaAuth.signInWithRedirect();

  return (
    <div>
      <div className="main-header">
        <div className="row d-flex justify-content-between">
          <div className="col-6 d-flex pt-2">
            <div className="img">
              <img src="/assets/swoosh-white.svg" alt="Nike Swoosh" />
            </div>
            <h4 className="text-header">FlexPLM Security Management - {userEmail}</h4>
          </div>

          <div className="col-6 d-flex justify-content-end">
            <div className="env-btn-div">
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  {`Environment: ${currentEnv}`}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {envArray.map((env) => (
                    <Dropdown.Item key={env.displayText} onClick={() => onViewClickHandler(env.displayText)}>
                      {env.displayText}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Button className="p-button p-component p-button-raised" onClick={() => publish()} label="Publish ACLs to Pipeline" />

            {authState.isAuthenticated && <Button className="p-button p-component p-button-raised" onClick={() => logout()} label="Logout" />}

            {!authState.isPending && !authState.isAuthenticated && <Button className="p-button p-component p-button-raised" onClick={() => login()} label="Logout" />}
          </div>
        </div>
      </div>
      <Navbar />

      <Dialog header={`Are you sure to change to ${nextEnv}?`} visible={confirmModalShow} modal style={{ width: "40vw" }} closable={false} onHide={hideConfirmModal}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3 mt-2" style={{ fontSize: "2rem" }} />
          <span className="p-confirm-dialog-message">Are you sure that you want to switch to {nextEnv}?</span>
        </div>
        <div className="dialog-footer">
          <Button label="Yes" icon="pi pi-check" onClick={acceptFunc} className="p-button-raised btn-success" />
          <Button label="No" icon="pi pi-times" onClick={rejectFunc} className="p-button-raised btn-info" />
        </div>
      </Dialog>
      <PopOver isOpen={getModalOpen} header={getModalHeader} message={getModalMessage} progress={getModalProgress} closeModal={closeModal} />
    </div>
  );
}
