/* eslint-disable import/no-extraneous-dependencies */
import { BehaviorSubject } from "rxjs";

const obsOktaEmail = new BehaviorSubject<string>(null);
const obsOktaToken = new BehaviorSubject<string>(null);
const obsOktaAdmin = new BehaviorSubject<boolean>(false);

const obsToastData = new BehaviorSubject({ severity: null, summary: null, detail: null });

const obsPipeLines = new BehaviorSubject([]);
const obsEnvName = new BehaviorSubject(null);

export { obsOktaEmail, obsOktaAdmin, obsOktaToken, obsToastData, obsPipeLines, obsEnvName };
