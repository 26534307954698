/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions, react/jsx-indent, no-template-curly-in-string, react/no-array-index-key, brace-style, import/order, react/jsx-no-useless-fragment */

import React, { useRef } from "react";
import { deleteUrl, postUrl } from "src/util/axios-rest";
import { pipeLinesReplenish, pipeLinesDelete } from "src/util/constants";
import "./pipeline-card.scss";
import { Toast } from "primereact/components/toast/Toast";

export function PipelineCard({ env }) {
  const toast = useRef<Toast>(null);
  const { pipeline } = env;

  const onRefresh = (type) => {
    let replenish = {
      userGroups: false,
      flexTypes: false,
      ACLs: false,
    };
    if (type === "all") {
      replenish = {
        userGroups: true,
        flexTypes: true,
        ACLs: true,
      };
    } else {
      replenish[type] = true;
    }

    // const pipeline: string = env.pipeline.split(":")[1];
    const detail = `Refresh ${type} ACLs for ${pipeline}`;
    const payloadObj = {
      pipeline,
      replenish,
    };

    postUrl(pipeLinesReplenish, payloadObj).then(
      (res) => {
        toast.current?.show({ severity: "success", summary: "Success", detail, life: 3000 });
      },
      (err) => {
        toast.current?.show({ severity: "error", summary: "Failure", detail: err, life: 3000 });
      },
    );
  };

  const onDelete = () => {
    // const pipeline: string = env.pipeline.split(":")[1];
    // DELETE: /pcx/v1/pipelines/{pipeline}

    const pipeLinesDeleteUrl = `${pipeLinesDelete}/${pipeline}`;
    const detail = `Delete environment ${pipeline}`;
    const deleteAll = {
      userGroups: true,
      flexTypes: true,
      ACLs: true,
    };

    deleteUrl(pipeLinesDeleteUrl, deleteAll).then(
      (res) => {
        toast.current?.show({ severity: "success", summary: "Success", detail, life: 3000 });
      },
      (err) => {
        toast.current?.show({ severity: "error", summary: "Failure", detail: err, life: 3000 });
      },
    );
  };

  return (
    <div className="row p-3 border-secondary border-bottom">
      <div className="row">
        <h2 className="d-flex justify-content-center text-capitalize">{env.displayText}</h2>
      </div>

      <div className="row">
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-warning m-2"
            onClick={() => {
              onRefresh("userGroups");
            }}
          >
            Refresh Usergroups
          </button>
          <button
            type="button"
            className="btn btn-warning m-2"
            onClick={() => {
              onRefresh("flexTypes");
            }}
          >
            Refresh Flextypes
          </button>
          <button
            type="button"
            className="btn btn-warning m-2"
            onClick={() => {
              onRefresh("ACLs");
            }}
          >
            Refresh ACLs
          </button>
          <button
            type="button"
            className="btn btn-warning m-2"
            onClick={() => {
              onRefresh("all");
            }}
          >
            Refresh All
          </button>
          <button type="button" className="btn btn-danger m-2" onClick={onDelete}>{`Delete ${env.displayText}`}</button>
        </div>
      </div>
    </div>
  );
}
