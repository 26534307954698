/* eslint-disable prefer-destructuring */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, AxiosHeaders, HeadersDefaults } from "axios";
import { Toast } from "primereact/toast";
import { apiUrl } from "src/constants";
import { obsOktaToken } from "./observables";

interface InternalAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosHeaders;
}

interface ErrorMessage {
  message: string;
}

let internalAuthToken: string;
export const setAuthToken = (authToken: string) => {
  internalAuthToken = authToken;
};

// Reference to the Toast component
let toast: Toast | null = null;

// Set the toast reference when the component mounts
export const setToastRef = (ref: Toast | null) => {
  toast = ref;
};

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 30000,
});

obsOktaToken.subscribe((bearerToken) => {
  // Request interceptor
  if (bearerToken) {
    axiosInstance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        // check if we have a token
        config.headers.setAuthorization(`Bearer ${bearerToken}`);

        // always set content type
        config.headers.setContentType("application/json");
        return config;
      },
      (error: AxiosError) => Promise.reject(error),
    );
  }
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // check if we have a token
    if (internalAuthToken) {
      config.headers.setAuthorization(`Bearer ${internalAuthToken}`);
    }

    // always set content type
    config.headers.setContentType("application/json");
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response) {
      if (error.response && error.response.data && error.response.data) {
        const data: Partial<ErrorMessage> = error.response.data;
        toast?.show({
          severity: "error",
          summary: "Error",
          detail: data.message,
        });
      }
    } else if (error.message) {
      toast?.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    } else {
      toast?.show({
        severity: "error",
        summary: "Error",
        detail: "Unknown Error",
      });
    }
    return Promise.reject(error);
  },
);
