const adminGroups = ["App.PCX.SecurityMgmtApp.Admin"];

const oktaClientId = "nike.piccpd.pcx-securitymanagementapp";
const oktaScopes = ["openid", "profile", "email"];

const apiHostAddress = "pcx-securityapp-stg-api.pes-preprod.nike.com";
const oktaUrl = "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7";

const apiUrl = `https://${apiHostAddress}/stg`;
const redirectUri = "implicit/callback";
const postLogoutRedirectUrl = "https://nike-qa.oktapreview.com";

const websocketUrl = "wss://9m13zl65e0.execute-api.us-west-2.amazonaws.com/stg";

const useOkta = true;
const nonOktaUser = "test@localhost";

const envList = ["shortcycle", "pipelineA", "pipelineB", "pipelineC"];

const currentEnv = "stg";

export { envList, websocketUrl, oktaClientId, adminGroups, oktaScopes, postLogoutRedirectUrl, redirectUri, useOkta, oktaUrl, apiUrl, currentEnv, nonOktaUser };
