import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import classNames from "classnames";
import "./flex-type.scss";
import { FlexBase, FlexBasePropsInterface } from "./flex-type-interfaces";

export function FlexTypes({ flexBaseProps, btnClickHandle, isAutoSelectUG, setIsAutoSelectUG }: FlexBasePropsInterface) {
  const [flexBase, setSetFlexBase] = useState([]);

  useEffect(() => {
    setSetFlexBase([...flexBaseProps]);
  }, [flexBaseProps]);

  const toggleChecked = (userFlexToToggle: FlexBase, ind: number) => {
    const flexBaseEdit: FlexBase[] = [...flexBase];
    userFlexToToggle.selected = !userFlexToToggle.selected;
    flexBaseEdit[ind] = userFlexToToggle;
    setSetFlexBase([...flexBaseEdit]);
    btnClickHandle(userFlexToToggle.flexobject, userFlexToToggle.selected);
  };

  return (
    <>
      <div className="auto-select-checkbox border-bottom-light">
        <span className="fs-6"> Auto Select User Groups: </span>
        <input className="toggle-checkbox ms-2" type="checkbox" checked={isAutoSelectUG} onChange={() => setIsAutoSelectUG(!isAutoSelectUG)} />
      </div>

      {flexBase.map((flexBaseEle: FlexBase, ind: number) => (
        <Button
          className={classNames("p-button p-button-outlined p-button-secondary p-button-raised", { "btn-selected": flexBaseEle.selected })}
          key={`button-${flexBaseEle.flexobject}`}
          label={flexBaseEle.flexobject}
          onClick={() => toggleChecked(flexBaseEle, ind)}
        />
      ))}
    </>
  );
}
