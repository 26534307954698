/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */

import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch, useHistory } from "react-router-dom";
import { SecureRoute, Security, LoginCallback, useOktaAuth } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { setAuthToken, setToastRef } from "src/services/axios";
import { Toast } from "primereact/toast";
import { obsOktaAdmin, obsOktaEmail, obsOktaToken } from "src/services/observables";
import { Main } from "./main/main";
import { useOkta, oktaUrl, oktaClientId, redirectUri, adminGroups, nonOktaUser } from "../constants";
import { EnvList } from "./env-list/env-list";

import CorsErrorModal from "../util/CorsErrorModal";
import AuthRequiredModal from "../util/AuthRequiredModal";

let pageOktaAuth: OktaAuth;

const setRxjsFromOkta = (oktaAuth: OktaAuth) => {
  if (useOkta) {
    if (oktaAuth.authStateManager._authState) {
      const email = oktaAuth.authStateManager._authState.idToken.claims.email;
      const groups = oktaAuth.authStateManager._authState.idToken.claims.groups;
      const allowedGroups = adminGroups.map(String);
      const found = Array.isArray(groups) && groups.some((r) => allowedGroups.includes(String(r)));
      obsOktaAdmin.next(found);
      obsOktaEmail.next(email);
      oktaAuth.tokenManager.get("accessToken").then((authToken) => {
        // debugger;
        obsOktaToken.next(authToken.accessToken);
        // console.error("token set " + authToken.accessToken);
      });
    } else if (window.location.pathname === `/${redirectUri}`) {
      // do nothing
    } else {
      // trigger login
      pageOktaAuth.signInWithRedirect();
    }
  }
};

if (useOkta) {
  const redirectUriFull: string = `${window.location.origin}/${redirectUri}`;
  pageOktaAuth = new OktaAuth({
    issuer: oktaUrl,
    clientId: oktaClientId,
    redirectUri: redirectUriFull,
  });

  if (pageOktaAuth) {
    setRxjsFromOkta(pageOktaAuth);
  }
}

function InternalApp() {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = useState(false);
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false);

  const history = useHistory(); // example from react-router

  const triggerLogin = async () => {
    await pageOktaAuth.signInWithRedirect();
  };

  useEffect(() => {
    if (!useOkta) {
      obsOktaEmail.next(nonOktaUser);
      obsOktaAdmin.next(true);
      obsOktaToken.next("NO-TOKEN");
    }
  }, []);

  if (useOkta) {
    const restoreOriginalUri = (oktaAuth: OktaAuth, originalUri) => {
      history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
      setRxjsFromOkta(oktaAuth);
    };

    const customAuthHandler = async () => {
      const previousAuthState = pageOktaAuth.authStateManager.getPreviousAuthState();
      if (!previousAuthState || !previousAuthState.isAuthenticated) {
        // App initialization stage
        await triggerLogin();
      } else {
        // Ask the user to trigger the login process during token autoRenew process
        setAuthRequiredModalOpen(true);
      }
    };

    return (
      <Security oktaAuth={pageOktaAuth as OktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
        <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
        <AuthRequiredModal {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }} />
        <Switch>
          <Route path="/login/callback" component={LoginCallback} />
          <Route path={`/${redirectUri}`} component={LoginCallback} />
          <SecureRoute exact path="/" component={Main} />
          <SecureRoute exact path="/admin" component={EnvList} />
        </Switch>
      </Security>
    );
  } else {
    return (
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/admin" component={EnvList} />
      </Switch>
    );
  }
}

function App() {
  return (
    <BrowserRouter>
      <Toast ref={(ref) => setToastRef(ref)} />
      <InternalApp />
    </BrowserRouter>
  );
}

export default App;
