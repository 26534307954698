/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions, no-template-curly-in-string, react/no-array-index-key, brace-style, import/order, react/jsx-no-useless-fragment */

import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import classNames from "classnames";
import "./style.scss";

const publicConfig = [
  {
    icon: "bi bi-people-fill",
    href: "/",
    id: "Flexes",
    label: "Flexes",
    "data-e2e": "allres",
  },
  {
    icon: "bi bi-person-fill-gear",
    href: "/admin",
    id: "Admin",
    label: "Admin",
    "data-e2e": "admin",
  },
];

export function Navbar() {
  // Hook for login return
  // const useLocationCalled = useLocation();
  const history = useHistory();
  const location = useLocation();

  const [selected, setSelected] = useState(0);

  const handleButtonClick = (index, url) => {
    setSelected(index);
    history.push(url);
  };

  useEffect(() => {
    const currentURL = location.pathname;
    if (currentURL.includes("/admin")) {
      setSelected(1);
    }
  }, []);

  // const role = hasRole();
  const navConfig = publicConfig;
  // if (role) {
  //   navConfig = publicConfig.concat(secureConfig);
  // }

  return (
    <nav className="custom-nav-bar">
      <div className="dummy-div" />
      {navConfig.map((e, ind) => {
        let buttonClass = "custom-btn-inactive";

        // if the current iter is the selected
        if (selected === ind) {
          buttonClass = "custom-btn-active";
        }

        return (
          <button key={ind} type="button" aria-label="submit" className={classNames("custom-nav-btn", buttonClass)} data-toggle="tooltip" data-placement="bottom" title={e.id} onClick={() => handleButtonClick(ind, e.href)}>
            <i className={`${e.icon} fs-2 nav-icon`} />
          </button>
        );
      })}
    </nav>
  );
}
