/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { urlAcls, urlFlexTypes, urlUserGroups, urlUserGroupsOfFlexType } from "src/util/constants";
import { axiosInstance } from "src/services/axios";
import { obsEnvName, obsOktaToken } from "src/services/observables";
import { replaceUrlPipeline } from "../../util/functions";
import { FlexTypes } from "../flex-type/flex-type";
import { UserGroup } from "../user-groups/user-group-interfaces";
import { FlexBase } from "../flex-type/flex-type-interfaces";
import "./main.scss";
import { UserGroups } from "../user-groups/user-groups";
import { MainDataTable } from "../data-table/data-table";
import { AppBar } from "../AppBar";
import { FlexObjectAPIReturn, GridRow, GridRowCell, TableData } from "../data-table/data-table-interfaces";

const legend = [
  { key: "S", value: "Selected Participants" },
  { key: "X", value: "All Except Participants" },
  { key: "+", value: "Has been granted permission" },
  { key: "-", value: "Has permission removed" },
  { key: "FULL", value: "Full permissions" },
  { key: "C", value: "Create" },
  { key: "R", value: "Read" },
  { key: "U", value: "Update" },
  { key: "D", value: "Delete" },
];

export function Main() {
  const [currentEnvName, setCurrentEnvName] = useState(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  // Flex Types
  const [flexBases, setFlexBases] = useState<FlexBase[]>([]);
  const [getAllFlexPaths, setAllFlexPaths] = useState<FlexObjectAPIReturn[]>([]);
  // User Groups
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  // TriState checkBox setTriStateVal
  const [triStateVal, setTriStateVal] = useState<boolean | null>(false);
  // For Auto Select User Group setIsAutoSelectUG
  const [isAutoSelectUG, setIsAutoSelectUG] = useState<boolean>(true);
  // Data Table setTableData
  const [gridRows, setGridRows] = useState<GridRow[]>([]);
  const unmounted = useRef(false);

  const fetchFlexTypes = async () => {
    try {
      const url = replaceUrlPipeline(urlFlexTypes, currentEnvName);
      const getFlexes = await axiosInstance.get(url);
      const allFlexPaths = getFlexes.data.data;
      setAllFlexPaths(allFlexPaths);

      const allFlexes = allFlexPaths.map((flexBase: FlexObjectAPIReturn) => flexBase.flexobject);
      const uniqueFlexesSet = new Set(allFlexes);
      const uniqueFlexes = Array.from(uniqueFlexesSet);

      const finalFlexes = uniqueFlexes
        .map(
          (flexBase: Partial<FlexBase>) =>
            ({
              flexobject: flexBase,
              selected: false,
            } as FlexBase),
        )
        .sort((a: FlexBase, b: FlexBase) => (a.flexobject > b.flexobject ? 1 : -1));
      if (!unmounted.current) {
        setFlexBases([...finalFlexes]);
      }
    } catch (error) {
      // Handle error
    }
  };

  const fetchUserGroups = async () => {
    try {
      const url = replaceUrlPipeline(urlUserGroups, currentEnvName);
      const getUserTypes = await axiosInstance.get(url);
      let getResData = getUserTypes.data.data;
      getResData = getResData.map(
        (userGroup: Partial<UserGroup>) =>
          ({
            ...userGroup,
            checked: false,
          } as UserGroup),
      );
      if (!unmounted.current) {
        setUserGroups([...getResData]);
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    if (currentEnvName) {
      obsOktaToken.subscribe((bearerToken) => {
        if (bearerToken) {
          fetchUserGroups();
          fetchFlexTypes();
        }
      });
    }
  }, [currentEnvName]);

  useEffect(() => {
    obsEnvName.subscribe((env) => {
      setCurrentEnvName(env);
    });
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    if (triStateVal) {
      const allChecked = userGroups.map((ug: UserGroup) => ({ ...ug, checked: true }));
      setUserGroups([...allChecked]);
    }
    if (triStateVal === null) {
      const allUnChecked = userGroups.map((ug: UserGroup) => ({ ...ug, checked: false }));
      setUserGroups([...allUnChecked]);
    }
  }, [triStateVal]);

  const fetchAllACLs = async (ugs: UserGroup[]) => {
    const selectedFlexes = [...flexBases].filter((flex: FlexBase) => flex.selected);
    const selectedFlexNames = selectedFlexes.map((flexBase: FlexBase) => flexBase.flexobject);
    const selectedUserGroups = [...ugs].filter((ug: UserGroup) => ug.checked);

    const allGridRows: GridRow[] = [];

    // iterate over each flex base and fetch
    for await (const flex of selectedFlexes) {
      const flexBase = flex.flexobject;

      const selectedUserGroupsName = selectedUserGroups.map((ug: UserGroup) => ug.name);

      const url = replaceUrlPipeline(urlAcls, currentEnvName);
      const Uri = `${url}?FlexTypes=${flexBase}&UserGroups=${selectedUserGroupsName.join(",")}`;
      const fetchAcls = await axiosInstance.get(Uri);

      // const UriBlank = `${url}?FlexTypes=${flexBase}&UserGroups=`;
      // const fetchBlankAcls = await axiosInstance.get(UriBlank);

      const gridRowCellsMixedBases: GridRowCell[] = fetchAcls.data.data;
      // const gridRowBlankCellsMixedBases: GridRowCell[] = fetchBlankAcls.data.data;

      // get the list of rows
      const uniqueFlextypePaths: string[] = getAllFlexPaths.filter((flexObjectAPIReturn: FlexObjectAPIReturn) => flex.flexobject === flexObjectAPIReturn.flexobject).map((flexObjectAPIReturn: FlexObjectAPIReturn) => flexObjectAPIReturn.flextypepath);

      uniqueFlextypePaths.forEach((flextypePath: string) => {
        const gridRow: GridRow = { display: true, flexObject: flextypePath, cells: [] };

        // api will return empty cells if we have no data
        // empty cells will not have an aclid
        // gridRow.cells = gridRowCellsMixedBases.filter((gridRowCell: GridRowCell) => gridRowCell.flextypepath === flextypePath && Object.hasOwn(gridRowCell, "aclid"));
        gridRow.cells = gridRowCellsMixedBases.filter((gridRowCell: GridRowCell) => gridRowCell.flextypepath === flextypePath);

        allGridRows.push(gridRow);
      });
    }

    // const finalTableData = makeUniqueArray(allGridRows).sort((a, b) => (a.flexType > b.flexType ? 1 : -1));
    const finalTableData = allGridRows.sort((a, b) => (a.flexObject > b.flexObject ? 1 : -1));
    setGridRows([...finalTableData]);
  };

  const onFlexBtnClick = async (flexStr: string, toCheck: boolean) => {
    const url = replaceUrlPipeline(urlUserGroupsOfFlexType, currentEnvName);
    const URI = url.replace(":flexType", flexStr);
    const getResp = await axiosInstance.get(URI);
    const getUGs = getResp.data.data.userGroups;

    let existingUGs = [...userGroups];
    if (isAutoSelectUG) {
      existingUGs = existingUGs.map((ug: UserGroup) => {
        const checkIfUGchecked = getUGs.some((existingUg: UserGroup) => existingUg.id === ug.id);
        let finalUg = { ...ug };
        if (checkIfUGchecked) {
          finalUg = {
            ...ug,
            flexType: toCheck ? flexStr : null,
            checked: toCheck,
          };
        }
        return finalUg;
      });
      setUserGroups([...existingUGs]);
    }
    fetchAllACLs(existingUGs);
  };

  const removeColumnFromTable = (userGroupName: string) => {
    const newUserGroups = [...userGroups].map((userGroup: UserGroup) => {
      let finalUserGroup = { ...userGroup };
      if (userGroup.name.toLowerCase() === userGroupName.toLowerCase()) {
        finalUserGroup = {
          ...userGroup,
          checked: false,
        };
      }
      return finalUserGroup;
    });
    setUserGroups([...newUserGroups]);

    const filteredData = [...gridRows].map((row) => {
      delete row[userGroupName];
      return row;
    });
    setGridRows([...filteredData]);
  };

  const userGroupToggle = (ugs: UserGroup, userGroupEdit: UserGroup[]) => {
    if (ugs.checked) {
      fetchAllACLs([...userGroupEdit]);
    } else {
      removeColumnFromTable(ugs.name);
    }
  };

  return (
    <div>
      <AppBar />
      <div className="container-fluid p-0">
        <Accordion className="fill-out" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <AccordionTab header="Flex Type and User Group Selection">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-6 fill-in">
                  <FlexTypes key="flex-type-container" flexBaseProps={flexBases} btnClickHandle={onFlexBtnClick} isAutoSelectUG={isAutoSelectUG} setIsAutoSelectUG={setIsAutoSelectUG} />
                </div>
                <div className="col-6 right-side">
                  <UserGroups key="user-group-container" userGroups={userGroups} triStateVal={triStateVal} setTriStateVal={setTriStateVal} userGroupToggle={userGroupToggle} />
                </div>
              </div>
            </div>
          </AccordionTab>
          <AccordionTab header="Click to See Data Table">
            <div className="row fill-in m-0">
              <div className="col-12 p-0">{gridRows.length === 0 ? <h2>Data Loading...</h2> : <MainDataTable key="main-table" tableData={{ userGroups, gridRows }} removeColumnFromTable={removeColumnFromTable} />}</div>
            </div>
          </AccordionTab>
          <AccordionTab header="Data Key and Legend">
            <div className="row fill-in m-0">
              <div className="col-12 p-0">
                <div className="row">
                  <div className="col-2" />
                  <div className="col-4 right bold p-2">Data Key</div>
                  <div className="col-4 bold p-2">Meaning</div>
                  <div className="col-2" />
                </div>
                {legend.map((legendItem) => (
                  <div className="row" key={`${legendItem.key}-${legendItem.value}`}>
                    <div className="col-2" />
                    <div className="col-4 right p-2">{legendItem.key}</div>
                    <div className="col-4 p-2">{legendItem.value}</div>
                    <div className="col-2" />
                  </div>
                ))}
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  );
}
