import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./env-list.scss";
import { obsPipeLines } from "src/services/observables";
import { switchEnvName } from "src/util/functions";
import { AppBar } from "../AppBar";
import { PipelineCard } from "../Pipeline-card/pipeline-card";

export function EnvList() {
  const history = useHistory();
  const [envArray, setEnvArray] = useState([]);

  const onViewClickHandler = (envName: string) => {
    history.push(`/?env=${envName}`);
    switchEnvName(envName);
  };

  useEffect(() => {
    obsPipeLines.subscribe((lines) => {
      setEnvArray([...lines]);
    });
  }, []);

  return (
    <div>
      <AppBar />
      <div className="container-lg">
        <div className="d-flex flex-column align-items-center">
          {envArray.length > 0 && envArray.map((env) => <PipelineCard key={env.displayText} env={env} />)}
          {envArray.length === 0 && <h2>No Pipelines Found!</h2>}
        </div>
      </div>
    </div>
  );
}
