/* eslint-disable import/no-cycle, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument, camelcase, no-use-before-define, @typescript-eslint/restrict-template-expressions, no-console, max-len, consistent-return, import/prefer-default-export, no-unreachable, no-alert, @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, import/no-unresolved, import/no-unresolved, import/extensions, no-param-reassign, @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */

import { CustomUserClaims, UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { obsEnvName } from "src/services/observables";
import { useOkta } from "../constants";

export const switchEnvName = (envName: string) => {
  obsEnvName.next(envName);
};

export function fetchEmail(): string {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [getUserEmail, setUserEmail] = useState<string>("test@localhost");

  if (useOkta) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { authState, oktaAuth } = useOktaAuth();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [userInfo, setUserInfo] = useState<UserClaims<CustomUserClaims>>(null);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!authState || !authState.isAuthenticated) {
        // When user isn't authenticated, forget any user info
        setUserInfo(null);
      } else {
        oktaAuth
          .getUser()
          .then((info) => {
            if (info && info.email) {
              setUserInfo(info);
              setUserEmail(info.email);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }, [authState, oktaAuth]); // Update if authState changes
  }
  return getUserEmail;
}

export const replaceUrlPipeline = (url: string, envName: string): string => {
  return url.replace(":pipeline", envName === "shortcycle" ? "sc" : envName);
};
