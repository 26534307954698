import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";
import "./websocket-popover.scss";

export function PopOver({ isOpen, header, message, progress, closeModal }) {
  const [progressBarMode, setProgressBarMode] = useState<"determinate" | "indeterminate">("determinate");
  const [getModalProgress, setModalProgress] = useState<number>(0);
  const [getDone, setDone] = useState(false);

  useEffect(() => {
    setModalProgress(progress);
  }, [isOpen, closeModal, progress]);

  useEffect(() => {
    const interval = setInterval(() => {
      setModalProgress((originalProgress: number) => {
        let newModalProgress = originalProgress;

        if (newModalProgress < 50) {
          newModalProgress += 1;
        } else if (newModalProgress < 70) {
          newModalProgress += 0.75;
        } else if (newModalProgress < 90) {
          newModalProgress += 0.5;
        } else {
          newModalProgress += 0.25;
        }

        if (newModalProgress >= 100) {
          setDone(true);
          clearInterval(interval);
          newModalProgress = 100;
        }

        // cap at 99% using this method
        else if (newModalProgress > 99) {
          setProgressBarMode("indeterminate");
          newModalProgress = 99;
          clearInterval(interval);
        }

        // return the new progress - Round to nearest quarter
        return newModalProgress;
      });
    }, 400);
    return () => clearInterval(interval);
  }, []);

  const OurProgressBar = <ProgressBar value={Math.floor(getModalProgress)} mode={progressBarMode} />;
  const progressBarComponent = (
    <div className="row">
      <div className="col-12">{OurProgressBar}</div>
    </div>
  );

  return (
    <Dialog
      style={{ width: "auto", height: "auto" }}
      closable={getDone}
      className="custom-modal"
      header="Publish ACLs to Pipeline"
      visible={isOpen}
      onHide={() => {
        closeModal();
      }}
    >
      <div className="">
        <div className="row mt-5">
          <div className="col-12">{message}</div>
        </div>
        {getDone ? <span /> : progressBarComponent}
      </div>
    </Dialog>
  );
}
