import { axiosInstance } from "src/services/axios";

export async function getUrl(url) {
  try {
    const ret = await axiosInstance.get(url);
    return sharedReturn(ret);
  } catch (err) {
    console.error(err);
  }
}

export async function postUrl(url, body) {
  try {
    const ret = await axiosInstance.post(url, body);
    return sharedReturn(ret);
  } catch (err) {
    console.error(err);
  }
}

export async function putUrl(url, body) {
  try {
    const ret = await axiosInstance.put(url, body);
    return sharedReturn(ret);
  } catch (err) {
    console.error(err);
  }
}

export async function deleteUrl(url, payload) {
  try {
    // TODO: look into why this change was needed
    // const ret = await axiosInstance.delete(url, body);
    const ret = await axiosInstance.delete(url, { data: payload });
    return sharedReturn(ret);
  } catch (err) {
    console.error(err);
  }
}

export function sharedReturn(ret) {
  // check for 401 error
  if (ret.status === 401) {
    let error = "401 return from XHR";
    if (ret.type === "cors") {
      error += "  CORS error.";
    }
    console.error(error);

    return {};
  }

  if (ret.status !== 200) {
    console.error("non-200 return");

    return {};
  }

  const { data } = ret;
  // check Unauthorized
  if (data && data.message === "Unauthorized") {
    return {};
  }
  return data;
}
